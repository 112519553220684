body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #101010;
}

.wrapper {
  padding: 0 16px;
}

header {
  background-color: #048ba8;
  padding: 0 16px;
}

.l-app__headerWrapper {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 781px) {
  .l-app__headerWrapper {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.l-app__headerLogo a {
  display: inline-block;
  color: #fff;
}

.l-app__logo {
  width: 120px;
}

.l-app__createWrap {
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.l-app__headerCreate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.l-app__headerCreateIcon {
  color: #fff;
}

.l-app__headerCreateText {
  color: #048ba8;
  font-weight: bold;
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
}

.l-app__headerMypageText {
  font-weight: bold;
  padding: 6px 10px;
  color: #fff;
}

.l-footer {
  margin-top: 40px;
  padding: 32px 24px 52px;
  background-color: #048ba8;
  color: #fff;
}

@media screen and (min-width: 781px) {
  .l-footer__wrapper {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
}

.l-footer__copyLight {
  display: inline-block;
  text-align: center;
  margin-top: 16px;
  color: #fff;
}

.l-footer__link {
  margin-top: 8px;
  color: #fff;
  line-height: 1.6;
}

.l-footer__linkText {
  margin-top: 8px;
}

.l-footer__link a {
  color: #fff;
}

.l-footer__title {
  line-height: 2;
  font-weight: bold;
}

.l-wrapper {
  padding: 0 16px;
}

.button {
  padding: 12px 4px;
  text-align: center;
  border-radius: 6px;
  border: none;
}

.c-button {
  padding: 12px 4px;
  text-align: center;
  border-radius: 6px;
  border: none;
}

.article {
  color: purple;
}

.p-kv {
  background: #048ba8;
  height: 250px;
  padding: 0 16px;
  color: #fff;
}

.p-kv__wrapper {
  background: url(../../src/images/img_kv.png) no-repeat;
  background-position: 95% 50%;
  background-size: 109px 130px;
}

@media screen and (min-width: 781px) {
  .p-kv__wrapper {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    background: url(../../src/images/img_kv.png) no-repeat;
    background-position: 95% 50%;
    background-size: 159px 180px;
  }
}

.p-kv__mainCopy {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 3px;
  padding-top: 28px;
}

.p-kv__subCopy {
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
}

.p-kv__buttonContent {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-kv__button {
  height: 70px;
  width: 47%;
  background-color: #e18f01;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.p-kv__buttonText {
  margin-top: 24px;
  vertical-align: middle;
  letter-spacing: 2px;
}

.p-kv__buttonText::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\f105";
  padding-left: 8px;
  color: #fff;
}

.p-kv__buttonTextEmphasis {
  font-size: 22px;
}

.p-policy {
  padding: 60px 16px 32px;
  line-height: 1.4;
}

@media screen and (min-width: 781px) {
  .p-policy {
    margin: 60px auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-policy__heading {
  margin-top: 20px;
}

.p-policy__list {
  padding-left: 18px;
  margin-top: 8px;
}

.p-kiyaku {
  padding: 60px 16px 32px;
  line-height: 1.4;
}

@media screen and (min-width: 781px) {
  .p-kiyaku {
    margin: 60px auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-kiyaku__heading {
  margin-top: 20px;
}

.p-kiyaku__list {
  padding-left: 18px;
  margin-top: 8px;
}

.p-contact {
  margin-top: 24px;
}

.p-nice__button {
  padding: 4px 12px;
  margin: 8px auto 0;
  background-color: #048ba8;
  border: none;
  color: #fff;
  border-radius: 3px;
}

.p-nice__icon {
  margin-right: 4px;
}

.p-shareButton {
  margin: 16px auto;
}

.p-shareButtonContent {
  margin: 0 4px;
}

.p-shareButton__url {
  margin-top: 16px;
}

.p-shareButton__urlButton {
  background-color: #e0e0e0;
  width: 100%;
  font-weight: bold;
}

.p-quizAnswerFinish__score {
  font-size: 28px;
  font-weight: bold;
  margin-top: 16px;
}

.p-quizAnswerFinish__scoreTitle {
  width: 80px;
  margin-top: 24px;
  position: relative;
  padding: 4px 26px 4px 12px;
  background: #048ba8;
  font-size: 14px;
  color: #fff;
  margin-left: -8px;
  line-height: 1.3;
  z-index: -2;
  font-weight: bold;
}

.p-quizAnswerFinish__scoreTitle::after {
  position: absolute;
  content: "";
  right: -3px;
  top: -7px;
  width: 0;
  height: 0;
  border: none;
  border-left: solid 40px transparent;
  border-bottom: solid 79px white;
  z-index: -1;
}

.p-quizAnswerFinish__wrap {
  margin-top: 60px;
}

@media screen and (min-width: 781px) {
  .p-quizAnswerFinish__wrap {
    margin: 60px auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-quizAnswerFinish__backBox {
  width: 100%;
  margin: 52px 0 40px;
}

.p-quizAnswerFinish__back {
  text-align: center;
  width: 100%;
  background-color: #e18f01;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
  display: inline-block;
}

.p-quizAnswerFinish__resultEnphasis {
  font-weight: bold;
}

.p-quizAnswerFinish__resultBox {
  margin: 24px 0;
  padding: 0;
  max-width: 400px;
  border-bottom: 1px solid #e0e0e0;
}

@media screen and (min-width: 781px) {
  .p-quizAnswerFinish__resultBox {
    max-width: 800px;
  }
}

.p-quizAnswerFinish__resultLabel {
  display: block;
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.p-quizAnswerFinish__resultLabel::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "\f078";
  padding-right: 8px;
  float: right;
}

.p-quizAnswerFinish__resultBox input {
  display: none;
}

.p-quizAnswerFinish__resultTextBox {
  height: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.p-quizAnswerFinish__resultCheck:checked+.p-quizAnswerFinish__resultLabel+.p-quizAnswerFinish__resultTextBox {
  height: auto;
  opacity: 1;
  padding: 12px 0;
}

.p-quizAnswerFinish__resultTextDescription {
  margin-top: 8px;
}

.p-quizAnswerFinish__resultCheck:checked+.p-quizAnswerFinish__resultLabel::after {
  content: "\f077";
}

.p-quizAnswerFinish__resultTextQuiz {
  margin-top: 8px;
}

.p-quizAnswerFinish__scorePoint {
  padding-left: 8px;
}

.p-quizAnswer {
  margin-top: 60px;
}

@media screen and (min-width: 781px) {
  .p-quizAnswer {
    margin: 60px auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-quizAnswer__subHeading {
  width: 80px;
  position: relative;
  padding: 5px 26px 5px 12px;
  background: #048ba8;
  font-size: 14px;
  color: #fff;
  margin-left: -8px;
  line-height: 1.3;
  z-index: -2;
}

.p-quizAnswer__subHeading::after {
  position: absolute;
  content: "";
  right: -3px;
  top: -7px;
  width: 0;
  height: 0;
  border: none;
  border-left: solid 40px transparent;
  border-bottom: solid 79px white;
  z-index: -1;
}

.p-quizAnswer__Problemtitle {
  line-height: 1.4;
  margin-top: 16px;
  font-weight: bold;
}

.p-quizAnswer__Percentage {
  margin-top: 8px;
}

.p-quizAnswer__nextAction {
  margin-top: 32px;
}

.p-quizAnswer__resultText {
  font-weight: bold;
  margin-top: 32px;
}

.p-quizAnswer__resultAnswerEmphasis {
  font-size: 20px;
  font-weight: bold;
}

.p-quizAnswer__resultAnswer {
  margin-top: 12px;
}

.p-quizAnswer__result {
  width: 100%;
  margin: 24px auto;
  text-align: center;
  background-color: #e18f01;
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
}

.p-quizAnswer__answerButton {
  background-color: #e0e0e0;
  color: #101010;
  font-weight: bold;
  width: 100%;
  margin-top: 20px;
}

.p-quizAnswer__answerButton:not(:first-of-type) {
  margin-top: 12px;
}

.p-quizAnswer__false {
  color: #da2a2a;
}

.p-quizAnswer__selectedFalse {
  background: #da2a2a;
  color: #fff;
  font-weight: normal;
}

.p-quizAnswer__true {
  color: #42a804;
}

.p-quizAnswer__selectedTrue {
  background: #42a804;
  color: #fff;
}

.p-quizAnswer__title {
  font-size: 24px;
  line-height: 1.4;
  margin-top: 10px;
  font-weight: bold;
}

.p-quizAnswer__mainTitleText {
  margin-top: 8px;
  line-height: 1.6;
}

.p-quizAnswer__info {
  margin-top: 12px;
}

.p-quizAnswer__text {
  line-height: 1.6;
}

.p-quizAnswer__icon {
  margin-right: 4px;
}

.p-quizAnswer__button {
  background-color: #e18f01;
  color: #fff;
  font-weight: bold;
  width: 100%;
  margin-top: 28px;
}

.p-quizAnswer__resultAnswerText {
  margin-top: 8px;
  line-height: 1.4;
}

.p-searchKeyWords {
  margin-top: 32px;
}

.p-searchKeyWords__label {
  font-size: 20px;
  font-weight: bold;
}

.p-searchKeyWords__input {
  display: block;
  border: 1px solid #e0e0e0;
  margin-top: 12px;
  width: 100%;
  height: 32px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.p-searchKeyWords__listBox {
  list-style: none;
  border: 1px solid #e0e0e0;
}

.p-searchKeyWords__listBox:first-child {
  border-top: none;
}

.p-searchKeyWords__listInfo {
  width: 90%;
}

.p-searchKeyWords__listWrap {
  padding: 12px 0;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-searchKeyWords__listTitle {
  font-weight: bold;
  line-height: 1.4;
}

.p-searchKeyWords__listDescription {
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-searchCategory {
  margin-top: 32px;
}

.p-searchCategory__label {
  font-size: 20px;
  font-weight: bold;
}

.p-searchCategory__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 12px;
}

.p-searchCategory__tag {
  border-radius: 16px;
  border: 1px solid #757575;
  padding: 8px 16px;
  display: inline-block;
}

.p-searchCategory__tagSelected {
  background-color: #048ba8;
  color: #fff;
}

.p-searchCategory__tagWrap {
  margin-right: 4px;
  margin-top: 4px;
  cursor: pointer;
}

.p-searchCategory__text {
  margin-top: 8px;
}

.p-searchCategory__resultListBox {
  list-style: none;
  border-top: 1px solid #e0e0e0;
}

.p-searchCategory__resultListBox:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.p-searchCategory__resultListWrap {
  padding: 12px 0;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-searchCategory__resultListInfo {
  width: 90%;
}

.p-searchCategory__resultListTitle {
  font-weight: bold;
  line-height: 1.4;
}

.p-searchCategory__resultListDescription {
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (min-width: 781px) {
  .p-myPage {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-myPage__heading {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 20px;
}

.p-myPage__logout {
  text-align: center;
  margin-top: 24px;
}

.p-myPage__logoutButton {
  padding: 12px 30px;
  background-color: #e0e0e0;
  color: #757575;
  border-radius: 6px;
  border: none;
}

.p-myPage__listBox {
  list-style: none;
  border-top: 1px solid #e0e0e0;
}

.p-myPage__listBox:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.p-myPage__listWrap {
  padding: 12px 0;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-myPage__listInfo {
  width: 92%;
}

.p-myPage__listTitle {
  font-weight: bold;
  line-height: 1.4;
}

.p-myPage__listDescription {
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-myPage__listDetail,
.p-myPage__listDetail--Visiter,
.p-myPage__listDetail--Nice {
  display: inline-block;
  margin-top: 12px;
  color: #757575;
}

.p-myPage__listDetail::before,
.p-myPage__listDetail--Visiter::before,
.p-myPage__listDetail--Nice::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  padding-right: 4px;
  color: #048ba8;
}

.p-myPage__listDetail--Visiter::before {
  content: "\f406";
}

.p-myPage__listDetail--Nice {
  padding-left: 8px;
}

.p-myPage__listDetail--Nice::before {
  content: "\f164";
}

.p-myPage__total {
  width: 50%;
  text-align: center;
}

.p-myPage__total+.p-myPage__total {
  border-left: 1px solid #757575;
}

.p-myPage__totalNumber {
  font-size: 37px;
  color: #048ba8;
  font-weight: bold;
}

.p-myPage__myContribution {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 44px;
}

.p-myPage__totalText {
  margin-top: 4px;
}

.p-myPage__listUpdate {
  padding: 4px 8px;
  background-color: #e0e0e0;
  margin-top: 8px;
  float: right;
  border-radius: 3px;
}

.p-auth__heading {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
}

.p-auth__button {
  margin-top: 16px;
}

.p-auth__text {
  margin-top: 16px;
  line-height: 1.6;
}

.form__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form__name {
  background-color: #fff;
  padding: 12px 16px;
}

.form__label {
  color: #757575;
  font-size: 12px;
}

.form__input {
  display: block;
  border: none;
  border-bottom: 2px #048ba8 solid;
  width: 100%;
  height: 32px;
  margin-top: 4px;
}

.p-quizCreate {
  background-color: #f2f2f2;
  padding: 52px 0 80px;
}

@media screen and (min-width: 781px) {
  .p-quizCreateWrap {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
}

.form__quizName {
  margin-top: 12px;
  background-color: #fff;
  padding: 12px 16px;
}

.form__textarea {
  display: block;
  border: none;
  border-bottom: 2px #048ba8 solid;
  width: 100%;
  height: 72px;
  margin-top: 4px;
  padding-bottom: 4px;
}

.form__quizTextarea {
  display: block;
  border: none;
  border-bottom: 2px #048ba8 solid;
  width: 100%;
  height: 35px;
  margin-top: 4px;
  padding-bottom: 4px;
}

.form__description {
  margin-top: 16px;
}

.form__completeWrap {
  background-color: #e18f01;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 781px) {
  .form__completeWrap {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    cursor: pointer;
  }
}

.form__completeButton {
  padding: 12px 0;
  width: 100%;
  color: #fff;
  font-weight: bold;
  border: none;
  background: none;
}

.form__add {
  padding: 12px 20px;
  background-color: #e0e0e0;
  color: #048ba8;
  border-radius: 6px;
  border: none;
}

.form__categorySelectBox {
  margin-top: 8px;
}

.form__openRange {
  margin-top: 8px;
}

.form__addWrap {
  text-align: center;
  margin: 32px auto;
}

.form__annotation {
  margin: 4px 0 0 8px;
}

.quizForm__label {
  color: #757575;
  font-size: 12px;
}

.quizForm {
  margin-top: 40px;
}

.quizForm__questionNumber {
  font-weight: bold;
}

.quizForm__quizContent {
  background-color: #fff;
  padding: 12px 16px;
}

.quizForm__input {
  display: block;
  border: none;
  border-bottom: 2px #048ba8 solid;
  width: 100%;
  height: 32px;
  margin-top: 4px;
}

.quizForm__deleteButton {
  color: #da2a2a;
  margin: 0;
  padding: 0;
}

.quizForm__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 16px 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox {
  display: none;
}

.checkbox+.checkboxIcon {
  position: relative;
  vertical-align: middle;
}

.checkbox+.checkboxIcon::before {
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  margin-right: 0.2rem;
}

.checkbox:checked+.checkboxIcon::before {
  background: url(../../src/images/icon_check.svg) no-repeat;
  color: #fff;
}

.p-quizFinish__wrap {
  margin-top: 60px;
}

@media screen and (min-width: 781px) {
  .p-quizFinish__wrap {
    margin: 60px auto;
    max-width: 800px;
    width: 100%;
  }
}

.p-quizFinish__backBox {
  width: 100%;
  margin: 70px 0 40px;
}

.p-quizFinish__back {
  text-align: center;
  width: 100%;
  background-color: #e18f01;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
  display: inline-block;
}

.p-quizFinish__Title {
  margin-top: 20px;
  width: 80px;
  font-weight: bold;
  position: relative;
  padding: 4px 26px 4px 12px;
  background: #048ba8;
  font-size: 14px;
  color: #fff;
  margin-left: -8px;
  line-height: 1.3;
  z-index: -2;
}

.p-quizFinish__Title::after {
  position: absolute;
  content: "";
  right: -3px;
  top: -7px;
  width: 0;
  height: 0;
  border: none;
  border-left: solid 40px transparent;
  border-bottom: solid 79px white;
  z-index: -1;
}

.p-quizFinish__quizName {
  font-size: 28px;
  font-weight: bold;
  margin-top: 16px;
}

.p-quizList__listBox {
  list-style: none;
  border-top: 1px solid #e0e0e0;
}

.p-quizList__listBox:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.p-quizList__listWrap {
  padding: 12px 0;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-quizList__listInfo {
  width: 90%;
}

.p-quizList__listTitle {
  font-weight: bold;
  line-height: 1.4;
}

.p-quizList__listDescription {
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-quizList__title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 12px;
}

.p-quizList__listDetail,
.p-quizList__listDetail--Visiter,
.p-quizList__listDetail--Nice {
  display: inline-block;
  margin-top: 4px;
  color: #757575;
}

.p-quizList__listDetail::before,
.p-quizList__listDetail--Visiter::before,
.p-quizList__listDetail--Nice::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  padding-right: 4px;
  color: #048ba8;
}

.p-quizList__listDetail--Visiter::before {
  content: "\f406";
}

.p-quizList__listDetail--Nice {
  padding-left: 8px;
}

.p-quizList__listDetail--Nice::before {
  content: "\f164";
}

@media screen and (min-width: 781px) {
  .p-home {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
}

.align {
  color: black;
}

.textXS {
  font-size: 10px;
}

.textS {
  font-size: 14px;
  line-height: 1.4;
}

.textM {
  font-size: 16px;
}

.textL {
  font-size: 20px;
}

.textWeight {
  font-weight: bold;
}

.u-textXS {
  font-size: 10px;
}

.u-textS {
  font-size: 14px;
  line-height: 1.4;
}

.u-textM {
  font-size: 16px;
}

.u-textL {
  font-size: 20px;
}

.u-textWeight {
  font-weight: bold;
}
